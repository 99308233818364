import { gql, useLazyQuery, useMutation } from '@apollo/client'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { setCookie } from 'nookies'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import useCacheUpdater from 'src/hooks/useCacheUpdater'
import ga from 'src/utils/gtag'
import { removeTokenCookie } from '../../apollo-client'
import Login from '../../components/Login'
import { USER_CHANGES_FRAGMENT, USER_FRAGMENT } from '../../constants/fragments'
import firebase from '../../firebase/firebaseClient'
import {
  AuthEmailType,
  IMutation,
  IQuery,
  IQueryCurrentUserArgs,
  IUser,
  Maybe
} from '../../types/graphql'
import useLogger from '../LoggerProvider/useLogger'
import { AuthContext, IAuthContext } from './index'

const ModalDrawer = dynamic(() => import('src/components/ModalDrawer'))

const CURRENT_USER = gql`
  ${USER_FRAGMENT}
  ${USER_CHANGES_FRAGMENT}
  query CurrentUser($referrer: String) {
    currentUser(referrer: $referrer) {
      ...CoreUserFields
      currency
      language
      referrer
      referrerStatus
      userChanges {
        ...UserChangesFields
      }
    }
  }
`

const SEND_AUTH_EMAIL = gql`
  mutation SendAuthEmail(
    $email: String!
    $emailType: AuthEmailType!
    $redirectUrl: String
  ) {
    sendAuthEmail(
      email: $email
      emailType: $emailType
      redirectUrl: $redirectUrl
    )
  }
`

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const auth = useAuth()

  const toggleLoginModal = useCallback(
    (open?: boolean) => {
      if (open !== undefined) setModalOpen(open)
      else setModalOpen(!modalOpen)
    },
    [modalOpen]
  )

  useEffect(() => {
    if (auth.isAuthenticated) {
      setModalOpen(false)
    }
  }, [auth.isAuthenticated])

  const value = useMemo(
    () => ({
      ...auth,
      toggleLoginModal
    }),
    [auth, toggleLoginModal]
  )

  return (
    <AuthContext.Provider value={value}>
      <ModalDrawer
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        title="Inicia sesión o regístrate"
        sx={{ maxWidth: '600px' }}
      >
        <Login isModal />
      </ModalDrawer>
      {children}
    </AuthContext.Provider>
  )
}

export default React.memo(AuthProvider)

/**
 * Hook
 */

export const useAuth = (): Omit<IAuthContext, 'toggleLoginModal'> => {
  const router = useRouter()
  const logger = useLogger()

  const [user, loading, error]: [
    firebase.User | null | undefined,
    boolean,
    firebase.auth.Error | undefined
  ] = useAuthState(firebase.auth())

  const [referrer, setReferrer] = useState<string>()
  useEffect(() => {
    if (user) return

    const queryNanoId =
      typeof router.query.nid === 'string' ? (router.query.nid as string) : null

    if (queryNanoId) {
      setReferrer(queryNanoId)
      localStorage.setItem('referrer', queryNanoId)
    } else {
      const storageNanoId = localStorage.getItem('referrer')
      if (storageNanoId) {
        setReferrer(storageNanoId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])

  if (error) {
    logger.error('Error retrieving firebase auth.', {}, error)
  }

  const [data, setData] = useState<Pick<IQuery, 'currentUser'> | undefined>()
  const [getCurrentUser, { loading: loadingCurrentUser, refetch }] =
    useLazyQuery<Pick<IQuery, 'currentUser'>, IQueryCurrentUserArgs>(
      CURRENT_USER,
      {
        onCompleted: (data) => {
          setData(data)
        },
        onError: (error) => {
          logger.error('Error retrieving currentUser', {}, error)
        },
        fetchPolicy: 'cache-and-network'
      }
    )

  const updateCurrentUser = useCacheUpdater<
    Pick<IQuery, 'currentUser'>,
    IQueryCurrentUserArgs
  >({
    query: CURRENT_USER,
    variables: { referrer }
  })

  const [sendAuthEmail] = useMutation<Pick<IMutation, 'sendAuthEmail'>>(
    SEND_AUTH_EMAIL,
    { onError: (error) => logger.error('Error sending auth email', {}, error) }
  )

  useEffect(() => {
    if (user) {
      setUserTokenCookie(user)
      getCurrentUser({ variables: { referrer } })
      ga.dataLayer().push({ user_id: user.uid })
    }
  }, [getCurrentUser, user, referrer])

  const value = useMemo(
    () => ({
      user,
      isAuthenticated: !!user,
      loading,
      loadingCurrentUser,
      currentUser: (user && data
        ? data.currentUser
        : undefined) as Maybe<IUser>,
      sendAuthEmail: async (
        email: string,
        emailType: AuthEmailType,
        redirectUrl?: string
      ) => sendAuthEmail({ variables: { email, emailType, redirectUrl } }),
      logout: async () => {
        setData(undefined)
        return firebase
          .auth()
          .signOut()
          .then(() => {
            removeTokenCookie()
          })
          .catch((error) => {
            logger.error('Error logging out', {}, error)
          })
      },
      refetchCurrentUser: async () => {
        refetch()
      },
      updateCurrentUser,
      referrer,
      setReferrer
    }),
    [
      user,
      loading,
      loadingCurrentUser,
      data,
      updateCurrentUser,
      referrer,
      sendAuthEmail,
      logger,
      refetch
    ]
  )

  return value
}

async function setUserTokenCookie(user: firebase.User): Promise<void> {
  const token = await user.getIdToken()
  setCookie(null, 'token', token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/'
  })
}
